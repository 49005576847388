<template>
  <module
    ref="module"
    id="whitelabelPlan"
    titleIcon="fa fa-newspaper-o"
    :title="$t('whitelabelplanpanel')"
    :filters.sync="filters"
    api-url="WLADMINAPIURL"
    url="/whitelabel_plan/search/"
    :useDefaultCrud="true"
    @on-save="validateAndSave"
    @on-select="fillName"
    @on-del="deletePlan"
    @on-edt="changeParams"
  >
    <template slot="table-columns">
      <el-table-column prop="name" :label="$t('name')" sortable="custom"></el-table-column>
    </template>
    <template slot="filter-field">
      <fg-input v-model="filters.name" :label="$t('name')"/>
    </template>
    <template slot="edt">
      <whitelabelPlanForm
        ref="form">
      </whitelabelPlanForm>
    </template>
  </module>
</template>
<script>

import Vue from "vue";
import {Table} from "element-ui";
import PPagination from '@/components/UIComponents/Pagination.vue'
import whitelabelPlanForm from "./WhitelabelPlanForm.vue";
import swal from "sweetalert2";

export default {
  components: {
    whitelabelPlanForm,
  },
  computed: {},
  data() {
    return {
      inProgress: true,
      filters: {
        name: '',
      },
      formData: undefined,
      selected_name: '',
      datatest: {
        name: '',
        params: []
      },
      isEditing: false,
    }
  },
  // only show names
  methods: {
    fillName: function (the_event, row) {
      if (row) {
        this.selected_name = row.name
      }
    },
    changeParams: function (row, the_event) {
      if (row) {
        this.isEditing = true
        this.getPlan(row)
      }
    },
    async getPlan(row) {
      const plandata = await this.$getByNameWlPlan(row.name)
      this.datatest.params = plandata.data
      this.datatest.name = plandata.data[0].name
      this.datatest.type = plandata.data[0].type
      this.$refs.form.editForm(this.datatest)
    },
    deletePlan() {
      if (this.selected_name != '') {
        this.inprogress = true;
        this.$deleteWhitelabelPlan(this.selected_name).then(
          this.persistSuccessHandler,
          this.persistErrorHandler
        );
        this.inprogress = false;
      }
    },
    validateAndSave() {
      const fail = resp => {
        if (resp.data !== undefined) {
          resp = resp.data.msg;
        }
        this.$toast.error(this.$t(resp));
      }

      this.$refs.form.serialize().then(this.submit, fail);
    },
    submit(submitData) {
      this.inprogress = true;
      if (!this.isEditing) {
        this.$createWhitelabelPlan(submitData.list).then(
          this.persistSuccessHandler,
          this.persistErrorHandler
        );
      } else {
        console.log(submitData)
        this.$updateWhitelabelPlan(submitData).then(
          this.persistSuccessHandler,
          this.persistErrorHandler
        )
      }
    },
    persistSuccessHandler(response) {
      this.inprogress = false;
      this.isEditing = false;
      if (!response.success) {
        this.persistErrorHandler();
        return;
      }
      swal({
        title: this.$t("successtxt"),
        text: null,
        type: 'success',
        allowOutsideClick: false,
        allowEscapeKey: false,
      }).then( () => this.$refs.module.refreshList());
    },
    persistErrorHandler() {
      this.inprogress = false;
      this.isEditing = false;
      swal(this.$t("attentiontxt"), this.$t("rdpdownmsg"), "error");
    },
  },
  mounted() {
    this.inProgress = false;
  },
  watch: {
    currentRow(value) {
      if (value && !value.template) {
        this.$refs
          .module
          .getDatatableRef()
          .then(datatable => datatable.queryChange('refresh'));
      }
    },
  },
}
</script>

<style scoped lang="scss">
@import '../../../../../assets/sass/paper/variables';

h5 {
  display: inline-block;
  padding: 1rem;
  margin: 0;

  i {
    padding-right: 1rem;
  }
}

.el-main {
  padding-top: 0;
  padding-bottom: 0;
}

.el-aside {
  border-left: $border $light-gray;

  fieldset {
    padding: 0 0 0 $padding-input-vertical;

    > label {
      font-weight: bold;
      font-size: $font-size-medium;
      color: $font-color;
    }
  }
}
</style>
