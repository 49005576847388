<template>
  <div v-if="inprogress" class="text-center">
    <h2>{{ $t("loadingtxt") }}</h2>
    <i class="fa fa-cog fa-spin fa-huge fa-fw" style="color: #51bcda"></i>
    <p></p>
  </div>

  <form v-else>
    <div class="row">
      <div class="col-md-6">
        <div class="row">
          <div class="col-md-12" style="margin-left: 10px">
            <h6>{{ $t("settings") }}</h6>
            <hr/>
          </div>

          <div class="col-md-12">

            <div class="col-md-12">
              <label>{{ $t('plan_name') }}</label>
              <fg-input
                  :disabled="isEditing"
                  v-model="planName"
                  :placeholder="$t('nametxt')"
                  style="margin-left: 1px"
                  type="text"
              >
              </fg-input>

              <div class="form-group mt-3 mb-2">
                <label>{{ $t('type') }}</label>
                <el-select v-model="planType" :disabled="isEditing" >
                  <el-option value="affiliate" :label="$t('affiliatetxt')"></el-option>
                  <el-option value="broker" :label="$t('broker')"></el-option>
                  <el-option value="trader" :label="$t('tradertxt')"></el-option>
                </el-select>
              </div>
            </div>
            <div class="col-md-12" v-for="item in plan_schema">
              <div v-if="item.type === 'number'">
                <label><b>{{ $t(item.parameter_text) }}</b></label>
                <fg-numeric v-model="item.value">
                </fg-numeric>
              </div>

              <div class="form-group mt-3 mb-2" v-if="item.type === 'select'">
                <label><b>{{ $t(item.parameter_text) }}</b></label>
                <el-select v-model="item.value">
                  <el-option v-for="(option, i) in item.options"
                             :key="i" :label="$t(option.label)" :value="option.value">
                  </el-option>
                </el-select>
              </div>
            </div>
          </div>
          <br>
        </div>
      </div>
      <div class="col-md-6 text-justify">
        <card>
          <div class="header"></div>
          <div class="body" v-for="item in plan_schema">
            <div v-if="item.type !== 'default'">
              <b>{{ $t(item.parameter_text) }}:</b><span> {{ $t(item.parameter_description) }} </span>
              <hr>
            </div>
          </div>
          <div class="footer"></div>
        </card>
      </div>
    </div>
  </form>
</template>

<script>

import {wl_plan_schema} from "@/models/whitelabel/whitelabel_plan";

export default {
  name: "whitelabelPlanForm",
  components: {},
  computed: {
  },
  data() {
    return {
      planName: "",
      planType: "",
      plan_schema: [],
      inprogress: true,
      isEditing: false,
    };
  },
  methods: {
    showHideInprogress() {
      this.inprogress = !this.inprogress;
    },
    async editForm(plan) {
      this.inprogress = true
      this.isEditing = true
      this.planName = plan.name
      this.planType = plan.type
      console.log(plan.type)
      await this.plan_schema.forEach(s => {
        let item = plan.params.find(o => o.parameter_name === s.parameter_name)
        if ((item.parameter_value === '0' || item.parameter_value === '1' || item.parameter_value === 'True' || item.parameter_value === 'False') && s.type === 'select') {
          s.value = (item.parameter_value === '1' || item.parameter_value === 'True')
        }
        else {
          return s.value = item.parameter_value
        }
      })
      await this.plan_schema.forEach(s => {
        let item = plan.params.find(o => o.parameter_name === s.parameter_name)
        return s["id"] = item.id
      })
      this.inprogress = false

    },
    async serialize() {
      return  {
        name: this.planName,
        type: this.planType,
        list: this.plan_schema.map(s => {
          let flat = [{
            name: this.planName,
            type: this.planType,
            parameter_name: s.parameter_name,
            parameter_value: s.value,
          }]
          const option = s.options.find(o => o.value = s.value)
          if (option && option.hasOwnProperty('children')) {
            flat = [
              ...flat,
              ...option.children.map(c => ({
                name: this.planName,
                type: this.planType,
                parameter_name: c.parameter_name,
                parameter_value: c.parameter_value,
              }))
            ]
          } else {
            flat = [...flat]
          }
          return flat
        }).flatMap(o => o)
      }
    },
    clean() {
      this.plan_schema = wl_plan_schema.filter(el => el.scope.includes('plan'))
    }
  },
  mounted() {
    this.clean()
    this.showHideInprogress()
  },

};
</script>

<style></style>
