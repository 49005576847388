import {i18n} from 'src/assets/i18n-plugin.js';

export const wl_plan_schema = [{
  parameter_name: 'SETUP_COST',
  parameter_description:  i18n.t('setup_cost_description'),
  parameter_text: i18n.t('setup_cost_txt'),
  type: 'number',
  value: 0.00,
  options: [],
  scope: ['plan'],
  category: 'COST',
}, {
  parameter_name: 'MAINTENANCE_COST',
  parameter_description: i18n.t('maintenance_cost_description'),
  parameter_text: i18n.t('maintenance_cost_txt'),
  type: 'number',
  value: 0.00,
  options: [],
  scope: ['plan'],
  category: 'COST',
}, {
  parameter_name: 'MAINTENANCE_PERIODICITY',
  parameter_description: i18n.t('maintenance_periodicity_description'),
  parameter_text: i18n.t('maintenance_periodicity_txt'),
  type: 'select',
  value: 'Daily',
  options: [
    {label: i18n.t('daily'), value: 'daily'},
    {label: i18n.t('weekly'), value: 'weekly'},
    {label: i18n.t('monthly'), value: 'monthly'},
    {label: i18n.t('quarterly'), value: 'quarterly'},
    {label: i18n.t('semester'), value: 'semester'},
    {label: i18n.t('yearly'), value: 'yearly'
  },],
  scope: ['plan'],
  category: 'COST',
}, {
  parameter_name: 'COPY_TRADE_ACTIVE',
  parameter_description: i18n.t('copy_trade_active_description'),
  parameter_text: i18n.t('copy_trade_active_txt'),
  type: 'select',
  value: true,
  options: [{
    label: i18n.t('yestxt'),
    value: true,
    children: [{
      parameter_name: 'ROUTE_ACCOUNTS_MAIN',
      parameter_value: '/accounts/main'
    }, {
      parameter_name: 'ROUTE_ACCOUNTS_OPEN',
      parameter_value: '/accounts/open'
    }, {
      parameter_name: 'ROUTE_STRATEGIES_WATCHLIST',
      parameter_value: '/strategies/watchlist'
    }, {
      parameter_name: 'ROUTE_STRATEGIES_MARKETPLACE',
      parameter_value: '/strategies/market'
    }, {parameter_name: 'ROUTE_BILLING_MARKETPLACE', parameter_value: '/billing/marketplace'},]
  }, {
    label: i18n.t('notxt'),
    value: false,
    children: [
      { parameter_name: 'ROUTE_ACCOUNTS_MAIN', parameter_value: ''},
      { parameter_name: 'ROUTE_ACCOUNTS_OPEN', parameter_value: ''},
      { parameter_name: 'ROUTE_STRATEGIES_WATCHLIST', parameter_value: ''},
      { parameter_name: 'ROUTE_STRATEGIES_MARKETPLACE', parameter_value: ''},
      { parameter_name: 'ROUTE_BILLING_MARKETPLACE', parameter_value: ''},]
  }],
  scope: ['plan'],
  category: 'COST',
}, {
  parameter_name: 'BRANDED_VPS_ACTIVE',
  parameter_description: i18n.t('branded_vps_active_description'),
  parameter_text: i18n.t('branded_vps_active_txt'),
  type: 'select',
  value: true,
  options: [{
    label: i18n.t('yestxt'),
    value: true,
    children: [
      {parameter_name: 'ROUTE_MY_VPS', parameter_value: '/vps/request'},
      {parameter_name: 'ROUTE_PARTNER_VPS', parameter_value: '/vps/request'},
    ]
  }, {
    label: i18n.t('notxt'),
    value: false,
    children: [{parameter_name: 'ROUTE_MY_VPS', parameter_value: ''}, {
      parameter_name: 'ROUTE_PARTNER_VPS',
      parameter_value: ''
    },]
  }],
  scope: ['plan'],
  category: 'COST',
}, {
  parameter_name: 'PAYMENT_PROCESSING',
  parameter_description: i18n.t('payment_processing_description'),
  parameter_text: i18n.t('payment_processing_txt'),
  type: 'select',
  value: 'auto',
  options: [
    {label: i18n.t('auto'), value: 'auto'},
    {label: i18n.t('manual'), value: 'manual'},
    {label: i18n.t('3rdparty'), value: '3rdparty'}
  ],
  scope: ['plan'],
  category: 'COST',
}, {
  parameter_name: 'BROKERS_ACTIVE',
  parameter_description: i18n.t('brokers_active_description'),
  parameter_text: i18n.t('brokers_active_txt'),
  type: 'select',
  value: 'One',
  options: [
    {
      label: i18n.t('one'),
      value: 'one',
      children: [
        {parameter_name: 'AUTO_ADD_BROKER', parameter_value: false}
      ]
    },
    {
      label: i18n.t('multiple'),
      value: 'multiple',
      children: [
        {parameter_name: 'AUTO_ADD_BROKER', parameter_value: false}
      ]
    },
    {
      label: i18n.t('all'),
      value: 'all',
      children: [
        {parameter_name: 'AUTO_ADD_BROKER', parameter_value: true}
      ]
    }
  ],
  scope: ['plan'],
  category: 'COST',
}, {
  parameter_name: 'TRADING_STRATEGIES',
  parameter_description: i18n.t('trading_strategies_description'),
  parameter_text: i18n.t('trading_strategies_txt'),
  type: 'select',
  value: 'admin',
  options: [{
    label: i18n.t('admin'),
    value: 'admin',
    children: [
      {parameter_name: 'AUTO_ADD_STRATEGIES',parameter_value: false},
      {parameter_name: 'SHOW_ALL_DEFAULT_MARKETPLACE', parameter_value: false},
      {parameter_name: 'CREATE_STRATEGIES', parameter_value: false},
      {parameter_name: 'ROUTE_AFFILIATE_FOLLOWERS',parameter_value: ''},
      {parameter_name: 'AUTO_ACTIVATE_STRATEGIES_FROM_WL_ADMIN', parameter_value: true},
    ]
  }, {
    label: i18n.t('selected'),
    value: 'Selected',
    children: [
      {parameter_name: 'AUTO_ADD_STRATEGIES',parameter_value: false},
      {parameter_name: 'SHOW_ALL_DEFAULT_MARKETPLACE', parameter_value: false},
      {parameter_name: 'CREATE_STRATEGIES',parameter_value: false},
      {parameter_name: 'ROUTE_AFFILIATE_FOLLOWERS', parameter_value: ''},
      {parameter_name: 'ADMIN_SELECT_STRATEGIES',parameter_value: true},
    ]
  }, {
    label: i18n.t('only_from_broker'),
    value: 'Only From Broker',
    children: [
      {parameter_name: 'AUTO_ADD_STRATEGIES',parameter_value: false},
      {parameter_name: 'SHOW_ALL_DEFAULT_MARKETPLACE', parameter_value: false},
      {parameter_name: 'CREATE_STRATEGIES',parameter_value: false},
      {parameter_name: 'ROUTE_AFFILIATE_FOLLOWERS',parameter_value: '/affiliate/followers'},
      {parameter_name: 'ONLY_STRATEGIES_FROM_BROKER', parameter_value: true},
    ]
  }, {
    label: i18n.t('all'),
    value: 'All',
    children: [
      {parameter_name: 'AUTO_ADD_STRATEGIES',parameter_value: false},
      {parameter_name: 'SHOW_ALL_DEFAULT_MARKETPLACE', parameter_value: false},
      {parameter_name: 'CREATE_STRATEGIES',parameter_value: false},
      {parameter_name: 'ROUTE_AFFILIATE_FOLLOWERS',parameter_value: '/affiliate/followers'},
      {parameter_name: 'AUTO_ACTIVATE_STRATEGIES', parameter_value: true},
    ]
  }],
  scope: ['plan'],
  category: 'COST',
}, {
  parameter_name: 'USE_OWN_IB_LINKS',
  parameter_description: i18n.t('use_own_ib_links_description'),
  parameter_text: i18n.t('use_own_ib_links_txt'),
  type: 'select',
  value: true,
  options: [{
    label: i18n.t('yestxt'),
    value: true,
    children: [
      {parameter_name: 'ROUTE_PARTNER_BROKER_PAGE',parameter_value: '/accounts/open'},
      {parameter_name: 'ALLOW_ADMIN_TO_ADD_URL_BROKER', parameter_value: true}
    ]
  }, {
    label: i18n.t('notxt'),
    value: false,
    children: [
      {parameter_name: 'ROUTE_PARTNER_BROKER_PAGE',parameter_value: ''},
      {parameter_name: 'ALLOW_ADMIN_TO_ADD_URL_BROKER', parameter_value: false}]
  }],
  scope: ['plan'],
  category: 'COST',
}, {
  parameter_name: 'SUB_AFFILIATION',
  parameter_description: i18n.t('sub_affiliation_description'),
  parameter_text: i18n.t('sub_affiliation_txt'),
  type: 'select',
  value: true,
  options: [{
    label: i18n.t('yestxt'),
    value: true,
    children: [
      {parameter_name: 'ROUTE_AFFILIATION_PAGE',parameter_value: '/affiliate/stats'},
      {parameter_name: 'WL_AFFILIATION_PERCENTAGE', parameter_value: 0.20},
      {parameter_name: 'WL_SUB_PERCENTAGE',parameter_value: 0.20},
    ]
  }, {
    label: i18n.t('notxt'),
    value: false,
    children: [
      {parameter_name: 'ROUTE_AFFILIATION_PAGE',parameter_value: ''},
      {parameter_name: 'WL_AFFILIATION_PERCENTAGE', parameter_value: 0},
      {parameter_name: 'WL_SUB_PERCENTAGE',parameter_value: 0},
    ]
  }],
  scope: ['plan'],
  category: 'COST',
}, {
  parameter_name: 'SIGN_UP_FEES_FOR_USERS',
  parameter_description: i18n.t('sign_up_fees_for_users_description'),
  parameter_text: i18n.t('sign_up_fees_for_users_txt'),
  type: 'select',
  value: true,
  options: [
    {label: i18n.t('yestxt'), value: true, children: [{parameter_name: 'SIGN_UP_FEE_COST', parameter_value: 0},]
  },
    {label: i18n.t('notxt'), value: false, children: [{parameter_name: 'SIGN_UP_FEE_COST', parameter_value: 0},]
  }],
  scope: ['plan'],
  category: 'COST',
}, {
  parameter_name: 'RECURRING_FEE_FOR_USERS',
  parameter_description: i18n.t('recurring_fee_for_users_description'),
  parameter_text: i18n.t('recurring_fee_for_users_txt'),
  type: 'select',
  value: true,
  options: [{
    label: i18n.t('yestxt'), value: true, children: [{parameter_name: 'RECURRING_FEE_COST', parameter_value: 0},]
  }, {
    label: i18n.t('notxt'), value: false, children: [{parameter_name: 'RECURRING_FEE_COST', parameter_value: 0},]
  }],
  scope: ['plan'],
  category: 'COST',
}, {
  parameter_name: 'RECURRING_PERIODICITY_FOR_USERS',
  parameter_description: i18n.t('recurring_periodicity_for_users_description'),
  parameter_text: i18n.t('recurring_periodicity_for_users_txt'),
  type: 'select',
  value: 'monthly',
  options: [
    {label: i18n.t('notxt'), value: false},
    {label: i18n.t('daily'), value: 'daily'},
    {label: i18n.t('weekly'),value: 'weekly'},
    {label: i18n.t('monthly'), value: 'monthly'},
    {label: i18n.t('quarterly'), value: 'quarterly'},
    {label: i18n.t('semester'),value: 'semester'},
    {label: i18n.t('yearly'), value: 'yearly'},
  ],
  scope: ['plan'],
  category: 'COST',
}, {
  parameter_name: 'FREE_PAYMENT_PROCESSING',
  parameter_description: i18n.t('free_payment_processing_description'),
  parameter_text: i18n.t('free_payment_processing_txt'),
  type: 'select',
  value: true,
  options: [
    {label: i18n.t('yestxt'), value: true},
    {label: i18n.t('notxt'), value: false}
  ],
  scope: ['plan'],
  category: 'COST',
}, {
  parameter_name: 'HOKO_SUPPORT_FOR_USERS',
  parameter_description: i18n.t('hoko_support_for_users_description'),
  parameter_text: i18n.t('hoko_support_for_users_txt'),
  type: 'select',
  value: true,
  options: [{
    label: i18n.t('yestxt'),
    value: true,
    children: [
      {parameter_name: 'HIDE_TELEGRAM_NOTIFICATIONS',parameter_value: false},
      {parameter_name: 'HIDE_NEED_HELP', parameter_value: false},
    ]
  }, {
    label: i18n.t('notxt'),
    value: false,
    children: [
      {parameter_name: 'HIDE_TELEGRAM_NOTIFICATIONS',parameter_value: true},
      {parameter_name: 'HIDE_NEED_HELP', parameter_value: true},
    ]
  }],
  scope: ['plan'],
  category: 'COST',
}, {
  parameter_name: 'WHITELABEL_DISCLAIMER',
  parameter_description: i18n.t('disclaimer_description'),
  parameter_text: i18n.t('disclaimer'),
  type: 'select',
  value: true,
  options: [
    {label: i18n.t('yestxt'), value: true},
    {label: i18n.t('notxt'), value: false}
  ],
  scope: ['plan'],
  category: 'COST',
}, {
  parameter_name: 'ROUTE_SUPPORT_FAQ_PAGE',
  parameter_description: '',
  parameter_text: '',
  type: 'default',
  value: '',
  options: [],
  scope: ['plan'],
  category: 'default',
}, {
  parameter_name: 'ROUTE_HOKO_AD_BANNER_PAGE',
  parameter_description: i18n.t(''),
  parameter_text: '',
  type: 'default',
  value: '',
  options: [],
  scope: ['plan'],
  category: 'default',
}, {
  parameter_name: 'HIDE_FREE_REPLICATION',
  parameter_description: i18n.t('wlbehavefreetradereplicationtxt'),
  parameter_text: '',
  type: 'default',
  value: true,
  options: [],
  scope: ['plan'],
  category: 'default',
}, {
  parameter_name: 'HIDE_INFO_BROKER',
  parameter_description: i18n.t('wlhideinfomationperbrokertxt'),
  parameter_text: '',
  type: 'default',
  value: true,
  options: [],
  scope: ['plan'],
  category: 'default',
},
  {
    parameter_name: 'HIDE_PREMIUM',
    parameter_description: 'wlbehaviorhidePremiumtxt',
    parameter_text: '',
    type: 'default',
    value: true,
    options: [],
    scope: ['plan'],
    category: 'default',
  },
  {
    parameter_name: 'INFORM_WHEN_NEW_USER_REGISTERED',
    parameter_description: i18n.t('inform_new_user_registered_text'),
    parameter_text: '',
    type: 'default',
    value: true,
    options: [],
    scope: ['plan'],
    category: 'default',
  },
];
